{
    "name": "wl-iaf",
    "version": "1.0.0",
    "license": "MIT",
    "scripts": {
        "start": "npm run start-basic -- --ssl --ssl-key ./certificates/ssl-dev/key.pem --ssl-cert ./certificates/ssl-dev/cert.pem --port=4201",
        "start-basic": "ng serve --proxy-config proxy.conf.js --disable-host-check --host 0.0.0.0",
        "server": "PORT=3001 nodemon --icu-data-dir=node_modules/full-icu server/app.www.js --watch server/",
        "server:cypress": "DB_CYPRESS=true npm run server",
        "service": "node server/app.www.js",
        "build": "ng build && npm run sentry:sourcemaps",
        "build-stats": "ng build --stats-json",
        "bundle-report": "webpack-bundle-analyzer dist/stats.json",
        "sentry-cli": "sentry-cli",
        "openssl:init": "npm run openssl:create && npm run openssl:trust",
        "openssl:create": "openssl req -nodes -x509 -newkey rsa:4096 -keyout ./certificates/ssl-dev/key.pem -out ./certificates/ssl-dev/cert.pem -config ./certificates/ssl-dev/certificate.cnf -days 3650",
        "openssl:trust": "sudo security add-trusted-cert -d -r trustRoot -k /Library/Keychains/System.keychain ./certificates/ssl-dev/cert.pem",
        "cypress:open": "cypress open",
        "e2e": "cypress run",
        "format": "npx prettier 'src/**/*.{js,jsx,ts,tsx,html,css,scss}' --write",
        "lint": "npx eslint 'src/**/*.{js,jsx,ts,tsx,html,css,scss}' --quiet --fix",
        "sentry:sourcemaps": "sentry-cli sourcemaps inject --org tactile --project wl-iaf ./dist && sentry-cli sourcemaps upload --org tactile --project wl-iaf ./dist",
        "extract-i18n": " ng extract-i18n --output-path src/assets/locale --format json"
    },
    "engines": {
        "node": "18.x"
    },
    "private": true,
    "dependencies": {
        "@angular/localize": "~16.2.12",
        "@fullcalendar/list": "^6.1.15",
        "@googlemaps/google-maps-services-js": "^3.3.31",
        "@googlemaps/js-api-loader": "^1.16.0",
        "@googlemaps/polyline-codec": "^1.0.28",
        "@sentry/angular-ivy": "^7.84.0",
        "@sentry/node": "^6.15.0",
        "@types/popper.js": "^1.11.0",
        "@woodl/extend-smart": "^1.0.3",
        "@woodl/google-api": "^2.0.2",
        "@woodl/hmg-models": "^2.2.16",
        "@woodl/models": "^2.0.8",
        "@woodl/orderwork-calculate": "^1.4.10",
        "app-root-path": "^3.0.0",
        "body-parser": "^1.19.0",
        "bootswatch": "^5.3.3",
        "compression": "^1.7.4",
        "cors": "^2.8.5",
        "dayjs": "^1.11.10",
        "debug": "^4.1.1",
        "email-validator": "^2.0.4",
        "express": "^4.17.1",
        "express-async-errors": "^3.1.1",
        "express-device": "^0.4.2",
        "express-rate-limit": "^5.1.1",
        "full-icu": "^1.3.1",
        "googleapis": "^118.0.0",
        "googlemaps": "^1.12.0",
        "lodash": "^4.17.21",
        "mailgun-js": "^0.22.0",
        "moment-timezone": "^0.5.33",
        "mongoose": "^6.8.3",
        "morgan": "^1.9.1",
        "postcode-nl": "^1.4.2",
        "request": "^2.88.2",
        "request-ip": "^2.1.3",
        "request-promise": "^4.2.5",
        "socket.io": "^2.4.1",
        "useragent": "^2.3.0"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^16.2.10",
        "@angular-eslint/builder": "^16.3.1",
        "@angular-eslint/eslint-plugin": "^16.3.1",
        "@angular-eslint/eslint-plugin-template": "^16.3.1",
        "@angular-eslint/schematics": "^16.3.1",
        "@angular-eslint/template-parser": "^16.3.1",
        "@angular/animations": "^16.2.12",
        "@angular/cli": "^16.2.10",
        "@angular/common": "^16.2.12",
        "@angular/compiler": "^16.2.12",
        "@angular/compiler-cli": "^16.2.12",
        "@angular/core": "^16.2.12",
        "@angular/forms": "^16.2.12",
        "@angular/language-service": "^16.2.12",
        "@angular/platform-browser": "^16.2.12",
        "@angular/platform-browser-dynamic": "^16.2.12",
        "@angular/platform-server": "^16.2.12",
        "@angular/router": "^16.2.12",
        "@fortawesome/angular-fontawesome": "^0.13.0",
        "@fortawesome/fontawesome": "^1.2.0-6",
        "@fortawesome/fontawesome-pro": "^6.3.0",
        "@fortawesome/fontawesome-svg-core": "^6.3.0",
        "@fortawesome/free-brands-svg-icons": "^6.3.0",
        "@fortawesome/pro-light-svg-icons": "^6.3.0",
        "@fortawesome/pro-regular-svg-icons": "^6.3.0",
        "@fortawesome/pro-solid-svg-icons": "^6.3.0",
        "@fullcalendar/angular": "^6.1.4",
        "@fullcalendar/core": "^6.1.15",
        "@fullcalendar/timegrid": "^6.1.4",
        "@popperjs/core": "^2.11.2",
        "@sentry/cli": "^2.23.0",
        "@types/express": "^4.17.3",
        "@types/google.analytics": "0.0.40",
        "@types/google.maps": "^3.53.2",
        "@types/lodash": "^4.14.171",
        "@types/node": "^13.9.0",
        "@typescript-eslint/eslint-plugin": "^5.53.0",
        "@typescript-eslint/parser": "^5.53.0",
        "core-js": "^3.6.4",
        "cypress": "^10.10.0",
        "eslint": "^8.17.0",
        "eslint-config-prettier": "^8.5.0",
        "eslint-plugin-prettier": "^4.2.1",
        "iframe-resizer": "^4.2.10",
        "inquirer": "^7.1.0",
        "ng-recaptcha": "^12.0.2",
        "ngx-bootstrap": "^11.0.2",
        "ngx-toastr": "^16.0.2",
        "nodemon": "^2.0.6",
        "prettier": "^2.7.1",
        "rxjs": "^7.8.1",
        "tippy.js": "^6.3.7",
        "typescript": "4.9",
        "webpack-bundle-analyzer": "^3.6.1",
        "zone.js": "~0.13.3"
    },
    "prettier": {
        "trailingComma": "es5",
        "tabWidth": 4,
        "singleQuote": true,
        "printWidth": 140,
        "semi": false
    }
}
